import StarRating from "./star-rating.component";
import { ArrowRightIcon } from "@heroicons/react/24/outline";
import { Link } from "@remix-run/react";
import { ProductAwardTypeLabels } from "~/utils/constants.utils";
import type { ProductAwardType } from "prisma-types";
import { tv } from "tailwind-variants";
import { AWARD_COLORS, AWARD_ICONS } from "~/utils/product-award.utils";

type BadgeColor = (typeof AWARD_COLORS)[keyof typeof AWARD_COLORS];
const badge = tv({
  base: "absolute bottom-0 left-0 right-0 py-1 px-3 text-sm flex items-center justify-center text-white",
  variants: {
    color: {
      emerald: "bg-emerald-500",
      violet: "bg-violet-400",
      teal: "bg-teal-500",
    },
  },
});

type ProductTileProps = {
  product: {
    name: string;
    avgRating: number;
    totalRatings: number;
    logo_file: { id: string | null };
    slug?: string;
    badge?: ProductAwardType;
  };
  className?: string;
  onClick?: () => void;
  showCTA?: boolean;
};

export default function ProductTile({
  product,
  className,
  onClick,
  showCTA = false,
}: ProductTileProps) {
  const AwardIcon = product.badge ? AWARD_ICONS[product.badge] : undefined;
  const badgeColor = product.badge
    ? (AWARD_COLORS[product.badge as ProductAwardType] as BadgeColor)
    : undefined;

  return (
    <div
      className={`w-full flex-shrink-0 rounded-xl overflow-hidden border border-gray-200 hover:shadow-lg transition ease-in-out duration-300 ${className} ${
        onClick ? "cursor-pointer" : ""
      } relative`}
      onClick={onClick}
    >
      <div className="relative">
        {product.logo_file?.id ? (
          <div
            className="h-36 md:h-48 bg-center bg-no-repeat bg-cover bg-white"
            style={{ backgroundImage: `url(/files/${product.logo_file.id})` }}
          ></div>
        ) : (
          <div className="h-36 md:h-48 text-center overflow-hidden flex items-center justify-center">
            <img
              src="/assets/default-logo.png"
              alt="Vendor Logo"
              height="600"
              width="600"
            />
          </div>
        )}
        {product.badge && badgeColor && (
          <div
            className={badge({
              color: badgeColor,
            })}
          >
            {AwardIcon && <AwardIcon className="size-4 mr-1.5" />}
            {ProductAwardTypeLabels[product.badge]}
          </div>
        )}
      </div>

      <div className="p-3 flex flex-col">
        <span className="truncate">{product.name}</span>
        <span className="flex text-gray-400">
          <StarRating rating={product.avgRating} className="mr-2"></StarRating>{" "}
          ({product.totalRatings})
        </span>
      </div>
      {showCTA && (
        <Link
          to={`/products/${product.slug}`}
          className="flex font-normal items-center text-sky-600 p-3"
        >
          Check it out
          <ArrowRightIcon className="h-4 w-4 ml-4" />
        </Link>
      )}
    </div>
  );
}
